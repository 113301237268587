<template>
  <v-row no-gutters class="mx-2 my-4">
    <v-btn class="mr-2" color="primary" outlined small @click="$emit('save')"><v-icon small class="mr-1">mdi-content-save</v-icon> Atualizar</v-btn>
    <v-btn color="error" outlined small @click="$emit('delete')"><v-icon small class="mr-1">mdi-cancel</v-icon> Deletar</v-btn>
  </v-row>
</template>

<script>
export default {}
</script>
